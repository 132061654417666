import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";

import styled from "styled-components";
import { Theme } from "../../../theme/css/theme";
import { Button, ErrorLabel, LargeModalBox } from "../../../theme/css/Global";

import CircleLoader from "../../shared/CircleLoader";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import { ToastsStore } from "react-toasts";
import apiClient from "../../../store/actions/ApiInterceptor";
import { Helmet } from "react-helmet";
import useClickHandler from "../../../hooks/useClickHandler/useClickHandler";


const moment = require("moment");
const _ = require("lodash");

function OfferDetails(props) {
	const { handleClick } = useClickHandler();


  // let { id } = useParams();
  const pathnameSplitted = props.location.pathname.split('/');
  const id = pathnameSplitted[pathnameSplitted.length - 1];
  const offerName = pathnameSplitted[pathnameSplitted.length -2];
  const [offer, setOffer] = useState({});

  const nameToUrl = (name)  => {
    name = name.replace(/[^a-zA-Z ]/g, "");
    name = name.replace(/  +/g, ' ');
    return name.replaceAll(' ', '-');
  }
  const dispatch = useDispatch();
  useEffect(() => {
    apiClient
      .get("offer/", {
        params: { id },
      })
      .then((res) => {
        const routeHeader = nameToUrl(res.data.results[0].brand.name + " " + res.data.results[0].title);
        if(offerName != routeHeader) {
          props.history.push(`/offer/${routeHeader}/${id}`);
        }
        setOffer(res.data.results[0]);
      })
      .catch((error) => {
        console.log(error)
        ToastsStore.error("Unable to fetch offers.");
      });    
  }, []);

  const {
    title,
    description,
    expiration_date,
    brand,
    link,
    is_saved,
  } = offer;
  const selectedOffer = offer;
  const {
    UserData,
    savedOfferDetails,
    isSavedOfferDeleted,
    emailDetails,
    isLoading,
  } = useSelector((state) => ({
    UserData: state.userState.UserDetails,
    savedOfferDetails: state.savedOfferState.savedOfferDetails,
    isSavedOfferDeleted: state.savedOfferState.isSavedOfferDeleted,
    emailDetails: state.userState.registerEmailDetails,
  }));


  if (_.get(savedOfferDetails, "id")) {
    if (
      _.get(offer, "id") ===
      _.get(savedOfferDetails, "offer_detail.id")
    ) {
      offer.is_saved.flag = true;
      offer.is_saved.saved_offer_id = savedOfferDetails.id;
      dispatch(actions.initialization());
    }
  }

  //after delete offer
  if (_.get(isSavedOfferDeleted, "flag")) {
    if (
      _.get(offer, "id") === _.get(isSavedOfferDeleted, "offerId")
    ) {
      offer.is_saved.saved_offer_id = null;
      offer.is_saved.flag = false;
      dispatch(actions.initialization());
    }
  }

  const toggleFavourite = (offerData) => {
    if (localStorage.getItem("token")) {
      if (offerData.is_saved.flag) {
        //if offer from saved slider or saveofferList
        if (_.get(offerData, "is_from_saved_offer")) {
          dispatch(
            actions.unSaveOffer(
              offerData.is_saved.saved_offer_id,
              offerData.is_saved.saved_offer_id
            )
          );
        } else {
          dispatch(
            actions.unSaveOffer(offerData.is_saved.saved_offer_id, offerData.id)
          );
        }
      } else if (_.get(UserData, "id")) {
        dispatch(actions.saveOffer(offerData.id, UserData.id));
      } else {
        ToastsStore.error(
          "Something went wrong. Please refresh the page and try again."
        );
      }
    } else {
      window.$("#offerDetailsModal").modal("hide");
      window.$("#recommendedForSignUp").modal("show");
    }
  };

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{offer?.title}</title>
        <meta name="description" content={offer?.description} />
      </Helmet>
      <div className="offer-details">
        <div className="body">
          <a onClick={()=>{handleClick(  {selectedOffer}, "Offer Details Image" )}} target="_blank" rel="noopener noreferrer">
          <img
            src={
              _.get(offer, "image")
                ? (is_saved?.flag)
                  ? offer?.image
                  : offer?.image
                : null
            }
            alt={offer?.title}
            className="image-linked"
          />
          </a>
          <p>
            <b className="medium">{_.get(brand, "name")}</b>
          </p>
          <h2>{description}</h2>
          <p className="pb-sm-3 pb-1">
            <b className="medium">Details</b>
          </p>
					<p className="offer-descripiton">{offer.phrase_description}</p>
          <p>{title}</p>
            <Button className="btn btn-primary mt-sm-5 mt-2 mb-3" 
						onClick={()=>{handleClick(  {selectedOffer}, "Offer Details Continue to Store" )}} 
            >
              Continue to Store
						</Button>
          <br />
        </div>
        <div
          className={
            localStorage.getItem("token")
              ? "footer after-login"
              : "footer"
          }
        >
          <div className="w-100 mx-auto-text-center d-md-none d-block">
            <div
              className="heart-circle "
              onClick={() => toggleFavourite(offer)}
            >
              <i
                className={
                  offer?.is_saved?.flag
                    ? "fa fa-heart"
                    : "fa fa-heart-o"
                }
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(OfferDetails);

const OfferDetailsContainer = styled.div`
 

 

  /* Header CSS START */

  .nav-up {
    top: -60px;
  }

  header.main-header {
    padding: 18px 0 18px 0;
  }

  header.main-header.nav-down {
    background: rgba(14, 17, 26, 0.7);
  }

  ul.header-icon-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  ul.header-icon-list li {
    display: inline-block;
    vertical-align: top;
  }
  ul.header-icon-list li:first-child {
    padding-right: 25px;
  }

  ul.header-icon-list li label {
    cursor: pointer;
  }

  ul.header-icon-list li label .hamburger:before {
    top: -7px;
    right: 0;
    content: "";
    width: 25px;
    height: 3px;
    border-radius: 3px;
    background: #fcfcfc;
    position: absolute;
    transition: 0.5s;
  }

  ul.header-icon-list li label .hamburger:after {
    top: 7px;
    right: 0;
    content: "";
    width: 25px;
    height: 3px;
    border-radius: 3px;
    background: #fcfcfc;
    position: absolute;
    transition: 0.5s;
  }

  header #menu-toggle {
    display: none;
  }

  ul.header-icon-list li label span {
    display: block;
    position: relative;
    margin: 0 0 3px 0;
    width: 20px;
    height: 2px;
    background: #fcfcfc;
    transition: 0.5s;
    z-index: 99999;
    transform-origin: 11px 0px;
    cursor: pointer;
  }

  ul.header-icon-list li label span:first-child {
    transform-origin: 0% 0%;
  }

  ul.header-icon-list li label span:nth-last-child(2) {
    transform-origin: 60% 100%;
  }

  header #menu-toggle:checked ~ .main-navigation {
    display: block;
  }

  header #menu-toggle:checked + label span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -2px);
  }

  header #menu-toggle:checked + label span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  header #menu-toggle:checked + label span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  header .main-navigation {
    display: none;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
    width: 50%;
    height: 100%;
    border-radius: 0px;
    padding: 40px 25px 20px;
    text-align: left;
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.2);
    font-family: "Helvetica-Neue-Reg";
    color: #ffffff;
  }

  header .main-navigation ul {
    list-style-type: none;
    margin: 0;
    padding: 30px 0 0 0;
  }

  header .main-navigation ul.nav-links li {
    padding: 2px 0px;
    display: block;
  }
  header .main-navigation ul.nav-links li a {
    transition: 0.3s;
    color: #ffffff;
    font-size: 32px;
  }

  header .main-navigation ul.nav-links li a:hover {
    padding-left: 5px;
    color: #64a3ff;
  }

  header .bottom-navigation {
    position: absolute;
    bottom: 10px;
  }

  header .main-navigation ul.social-icons li {
    display: inline-block;
    padding: 0;
    margin-right: 8px;
  }

  header .main-navigation ul.rights li {
    display: inline-block;
    color: #4d5160;
    font-size: 12px;
  }

  header .main-navigation ul.rights li a {
    color: #4d5160;
  }

  header .main-navigation ul.rights li a:hover {
    color: #ffffff;
  }

  header .main-navigation ul.rights li {
    padding: 0 0 0 10px;
  }

  header .main-navigation ul.rights li:first-child {
    padding-left: 0;
    padding-right: 0;
  }
  /* Header CSS END */

  /* Footer START */
  footer.footer {
    padding: 40px 0 50px;
    background-color: #151723;
  }

  footer .container {
    max-width: 1100px;
  }

  footer.footer .logo {
    margin-bottom: 34px;
    display: block;
  }

  header.main-header img.header-logo {
    width: 130px;
  }

  footer.footer p {
    color: #646a7a;
    padding-bottom: 10px;
    max-width: 280px;
    font-size: 13px;
  }

  footer.footer .logo img.footer-logo {
    width: 130px;
  }

  footer.footer .form-group {
    position: relative;
    max-width: 290px;
  }

  footer.footer .form-group .form-control {
    background-color: #2a2e3d;
    border: 0;
    border-radius: 0;
    padding: 10px 16px;
    color: #686e81;
  }

  footer.footer .form-group .form-control:focus {
    box-shadow: none;
    background-color: #3b4051;
  }

  footer.footer .form-group .send-btn {
    position: absolute;
    top: 2px;
    right: 0px;
    font-size: 12px;
    padding: 5px 8px;
    background: transparent;
    border: 0;
  }
  footer.footer .form-group .send-btn:focus {
    outline: 0;
  }
  footer.footer ul.social-media {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }

  footer.footer ul.social-media li {
    color: #fff;
    padding: 7px 0;
    vertical-align: top;
  }

  footer.footer ul.social-media li span {
    font-size: 14px;
    color: #9a9b9d;
    vertical-align: middle;
    margin-left: 7px;
  }

  footer.footer ul.social-media li i {
    margin-right: 10px;
    color: #c2c4c6;
    font-size: 18px;
  }

  footer.footer ul.footer-link {
    padding: 0 20px;
    margin: 20px 0;
    float: left;
    list-style-type: none;
    text-align: left;
  }

  footer.footer ul.footer-link li {
    padding: 6px 0;
  }
  footer.footer ul.footer-link li a {
    color: #e7e7e8;
  }

  footer.footer ul.footer-link li a:hover {
    color: #62a4fb;
  }

  footer.bottom-footer {
    background-color: #2a2e3d;
    padding: 20px 0;
    font-size: 14px;
    color: #565c6c;
  }

  footer.bottom-footer ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  footer.bottom-footer ul li {
    display: inline-block;
    padding: 0 12px;
  }

  footer.bottom-footer ul li a {
    color: #565c6c;
  }

  footer.bottom-footer ul li a:hover {
    color: #62a4fb;
  }

  /* Footer END */

 
 

  
`;
