import React, {useEffect, useState} from "react";
import Header from "./header/HeaderComponent";
import { useDispatch } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import OffersDashboard from "../application/offers/OffersDashboard";
import OfferList from "../application/offers/OfferList";
import SavedOfferList from "../application/offers/SavedOfferList";
import SearchOffersList from "../application/offers/SearchOffersList";
import MyAccountContainer from "../application/user/MyAccountContainer";
import Footer from "./footer/Footer";
import PrivateRoute from "../../services/PrivateRoute";
import * as actions from "../../store/actions";
import PrivacyPolicy from './footer/PrivacyPolicy';
import CAPrivacyRights from './footer/CAPrivacyRights';
import TermsAndConditions from './footer/TermsAndConditions';
import DoNotSellMyPersonalInformation from './footer/DoNotSellMyPersonalInformation';
import MedicarePartners from './footer/MedicarePartners';
import UnsubscribeComponent from './footer/UnsubscribeComponent';
import LandingPage from "../application/offers/LandingPage";
import CompareOffer from "../application/offers/CompareOffer";
import OfferDetails from "../application/offers/OfferDetails";
// import MyOffersList from "../application/offers/MyOffersList";
import AllOffers from "../application/offers/AllOffers";
import {Helmet} from "react-helmet";  // react library that creates a metadata component. 
import SignupPage from "../application/user/SignupPage";
import BrandDetails from "../application/brands/BrandDetails";

function MainContainer() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(actions.getUserDetails());
    }
  }, []);

  let [canonicalLink, setCanonicalLink] = useState("");
  useEffect(() => {
    setCanonicalLink( window.location.href);
  },[window.location.href] );



  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="The latest deals from brands you love." />
        <link rel="canonical" href={canonicalLink} />
        <title>Offers Club</title>
      </Helmet>
      <Header />
      <Switch>
        <Route
          exact
          path="/dashboard"
          component={OffersDashboard}
          isVerify={false}
          isResetPassword={false}
          key=""
        />
        <Route
            exact
            path="/offer/medicaid/landing/:zipcode?"
            component={LandingPage}
          />  {/**@NMI do i keep it? */}
          <Route exact path="/offer/medicaid" component={CompareOffer} /> {/**@NMI do i keep it? */}
        <Route path="/offer/:id" component={OfferDetails} /> {/*it's OK*/}
        <Route path="/brand/:id" component={BrandDetails} /> {/*it's OK*/}
        <Route path="/offers" component={OfferList} /> {/*it's OK */}
        <Route path="/all" component={AllOffers} /> {/*it's OK*/}
        <Route exact path="/saved-offers" component={SavedOfferList} /> {/* favorites list, it's OK it works */}
        {/* <Route exact path="/my-offers" component={MyOffersList} /> delete  */}
        <Route path="/search" component={SearchOffersList} />  {/**it's OK */}
        <PrivateRoute path="/account" component={MyAccountContainer} /> {/**Works it's ok! */}
        <Route exact path="/privacy-policy" component={PrivacyPolicy} /> {/** @NMY do i keep it? */}
        <Route exact path="/terms-and-conditions" component={TermsAndConditions} /> {/** @NMY do i keep it? */}
        <Route exact path="/CA-privacy-rights" component={CAPrivacyRights} /> {/* Empty component */}
        <Route exact path="/do-not-sell-my-personal-information" component={DoNotSellMyPersonalInformation} /> {/* route exists but i don't see a way to get there. delete route component? */}
        <Route exact path="/medicare-partners" component={MedicarePartners} />  {/*404 Not Found Delete route???*/}
        <Route exact path="/unsubscribe" component={UnsubscribeComponent} /> {/*route works*/}
        <Route exact path="/signup" component={SignupPage} />  {/**it's OK */}
        <Redirect from="/" to="/dashboard" />
      </Switch>
      <Footer />
    </React.Fragment>
  );
}

export default MainContainer;
