import { useEffect } from 'react';

function useSyncLocalStorageWithQueryParams() {
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const keys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6', 'lp_request_id'];

    keys.forEach((key) => {
      const queryValue = query.get(key);
      const storedValue = localStorage.getItem(key);

      if (storedValue !== queryValue && queryValue !== null) {
        localStorage.setItem(key, queryValue);
      }
    });
  }, [window.location.search]);
}

export default useSyncLocalStorageWithQueryParams;