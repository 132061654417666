import styled from "styled-components";
import {Theme} from "../css/theme";

import RightCheck from "../images/right-check.svg";

export const Table = styled.table`
	width: 100%;
	border: 1px solid rgba(255,255,255,0.2);
	tr {
		border-bottom: 1px solid rgba(255,255,255,0.2);
		th {
			width: 33%;
		}
		th:not(:last-of-type),td:not(:last-of-type) {
			border-right: 1px solid rgba(255,255,255,0.2);
		}
		
	}
`;

export const NewsletterSignUp = styled.div`

  p {
    &.contact-link {
      font-size: calc(${Theme["$base-f-size"]} + 1px) !important;
    }

    &.join-newsletter {
      max-width: 297px;
      // text-align:center;
      // margin: 0 auto;
    }
  }

 
  form {
    .form-group {
      position: relative;
      width: 100%;
      min-width: 290px;
      // margin: 0 auto;
  
      input {
        position: relative;
        border-radius: 25px;
        background-color: ${Theme["$gray-97"]};
        border: none;
        height: 2.8rem;
        color: ${Theme["$white-color"]};
        padding: 0 0 0 25px;
  
        &.form-control {
          &::placeholder {
            font-size: ${Theme["$base-f-size"]};
            color: ${Theme["$gray-25"]};
          }
        }
      }
  
      .send-btn {
        position: absolute;
        top: 11px;
        right: 10px;
        background: none;
        border: none;
      }
    }
  }
  
`;

export const BodyContent = styled.div`
  margin: 30px 0 80px;

  &.home-page {
    margin-top: 0;
  }

  .see-all {
    font-size: ${Theme["$extra-medium-f-size"]};
    color: ${Theme["$primary-color"]};
    cursor: pointer;
  }

  .description-para {
    font-size: ${Theme["$base-f-size"]};
    padding: 20px 0;
  }

  .brand-slider {
    img {
      border: 3px solid ${Theme["$gray-75"]};
    }
  }

  h1 {
    &.list-heading {
      font-size: ${Theme["$list-title-f-size"]};
      letter-spacing: 0.5px;
      margin: 12px 0 8px;

      @media only screen and (max-width: 575px) {
        font-size: 35px;
      }

      span {
        font-size: ${Theme["$large-f-size"]};
        color: ${Theme["$gray-10"]};
        font-family: ${Theme["$medium-font-family"]};

        @media only screen and (max-width: 575px) {
          font-size: 20px;
        }
      }
    }
  }

  .lighter {
    font-family: ${Theme["$base-font-family"]};
    font-size: ${Theme["$base-f-size"]};
    letter-spacing: 1px !important;

    span {
      cursor: pointer;

      &.active {
        color: ${Theme["$primary-color"]};
      }
    }
  }

  @media only screen and (max-width: 575px) {
    h1 {
      span {
        font-size: ${Theme["$base-title-f-size"]};
      }
    }

    h4 {
      &.sub-heading {
        font-size: 17px;
        padding-bottom: 30px;
        font-family: ${Theme["$base-font-family"]};
      }
    }
  }
`;

export const ModalBox = styled.div`
  &.log-out-modal {
    .modal-dialog {
      margin: 15% auto 0;

      h2 {
        font-size: 35px;

        @media only screen and (max-width: 575px) {
          font-size: 25px;
        }
      }
    }

    .modal-body {
      padding: 0px 0 45px 0;

      p.small {
        letter-spacing: 0.2px;
      }
    }
  }

  .modal-dialog {
    max-width: 412px;
    width: 100%;
    margin: 80px 130px 0 auto;

    &.modal-dialog-notification {
      margin: 62px 200px 0 auto;

      @media only screen and (max-width: 991px) {
        margin: 62px auto 0;
      }

      @media only screen and (max-width: 575px) {
        margin: 0px auto;
        max-width: 100%;
        position: absolute;
        top: 0;
        height: 100%;

        .modal-content {
          border-radius: 0;
        }
      }
    }

    @media only screen and (max-width: 991px) {
      margin: 62px auto 0;
    }

    @media only screen and (max-width: 575px) {
      max-width: 100%;
      position: absolute;
      bottom: 0;
    }
  }

  .modal-content {
    border-radius: 15px;
    background-color: ${Theme["$gray-95"]};
    box-shadow: 3px 3px 12px 5px rgba(0, 0, 0, 0.4);
    &.notification {
      height: 600px;
      overflow-y: scroll;

      ::-webkit-scrollbar {
        width: 0.7px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${Theme["$gray-6"]};
        border-radius: 50px;
      }

      .modal-header {
        padding: 0;

        .close {
          position: absolute;
          top: 10px;
          right: 48px;
          z-index: 99999;
        }
      }

      @media only screen and (max-width: 575px) {
        height: 100%;
      }
    }

    .modal-header {
      border: 0 !important;

      .close {
        font-size: 42px;
        font-family: ${Theme["$thin-font-family"]};
        color: ${Theme["$gray-15"]};
        padding: 6px 13px 0;
        opacity: 1;
        font-weight: 600;

        &:hover {
          outline: none;
        }
      }

      img {
        width: 150px;
        margin: 15px 0 0 15px;
        float: left;
      }
    }

    .modal-body {
      max-width: 300px;
      margin: 0 auto;
      width: 100%;
      text-align: left;
      padding: 13px 0 65px 0;

      .email-has-send {
        font-size: ${Theme["$medium-f-size"]};
        color: ${Theme["$gray-6"]};
        letter-spacing: 0.2px;
      }

      .modal-sub-heading {
        font-size: ${Theme["$extra-medium-f-size"]};
        color: ${Theme["$gray-6"]};
        margin: 10px 0;
      }

      .modal-subtitle-bold {
        font-family: ${Theme["$title-font-family"]};
        font-size: 17px;
        letter-spacing: 0.6px;
      }

      .resend-verification-link {
        font-size: 16px;
        color: ${Theme["$primary-color"]};
        text-decoration: underline;
      }

      .forgot-link {
        font-size: 15px;
        color: ${Theme["$primary-color"]};
        text-decoration: underline;
        cursor: pointer;
        letter-spacing: 0.5px;
      }

      .email-data {
        font-family: ${Theme["$medium-font-family"]};
        padding-top: 8px;
        font-size: 17px;
        padding-top: 8px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }

      .modal-title {
        font-family: ${Theme["$title-font-family"]};
        font-size: 17px;
        letter-spacing: 0.5px;
      }

      .info {
        font-size: ${Theme["$medium-f-size"]};
      }

      .btn-primary {
        letter-spacing: 0.5px;

        @media only screen and (max-width: 575px) {
          font-size: ${Theme["$base-f-size"]};
          font-family: ${Theme["$medium-font-family"]};
        }
      }

      &.notification-body {
        max-width: 370px;
        padding: 20px 0;

        h2 {
          padding: 13px 0 20px;
        }

        ul {
          &.notification-list {
            li {
              margin-right: 0;

              &:not(:last-child) {
                border-bottom: 0.5px solid ${Theme["$gray-40"]};
                margin-bottom: 15px;
                padding-bottom: 10px;
              }

              .notification-img {
                width: 40%;

                img {
                  width: 100%;
                  height: 100px;
                }
              }

              .text-part {
                width: 54%;
                margin-left: 20px;

                h5 {
                  margin: 5px 0 13px;
                }

                .expiring-soon-link {
                  font-size: ${Theme["$small-f-size"]};
                  font-family: ${Theme["$base-font-family"]};
                }

                p {
                  font-family: ${Theme["$base-font-family"]};
                  padding-top: 5px;
                }
              }

              @media only screen and (max-width: 361px) {
                .text-part {
                  margin-left: 15px;

                  h5 {
                    margin: 6px 0;
                  }

                  p {
                    padding-top: 0;
                  }
                }

                .notification-img {
                }
              }
            }
          }
        }
      }
    }

    .form-label-group {
      position: relative;
      margin-bottom: 15px;
    }

    .form-label-group > input {
      padding: var(--input-padding-y) var(--input-padding-x);
      padding: 16px 20px 10px;
      font-size: calc(${Theme["$extra-medium-f-size"]} - 1px);
      color: ${Theme["$gray-38"]} !important;
    }

    .form-label-group > label {
      padding: var(--input-padding-y) var(--input-padding-x);
      padding: 8px 20px 10px;
      font-size: ${Theme["$extra-medium-f-size"]};
      color: ${Theme["$gray-38"]};

      @media only screen and (max-width: 575px) {
        font-size: ${Theme["$medium-f-size"]};
      }
    }

    .form-control {
      height: 48px;

      &:focus {
      }
    }
    input {
      &::placeholder {
        font-size: 20px;
      }
    }

    .form-label-group > label {
      position: absolute;
      top: 5px;
      left: 0;
      display: block;
      width: 100%;
      margin-bottom: 0;
      font-weight: 300;
      line-height: 1.2;
      color: ${Theme["$gray-38"]};
      border: 1px solid transparent;
      font-family: ${Theme["$medium-font-family"]};
      border-radius: 0.25rem;
      transition: all 0.1s ease-in-out;
      cursor: text;

      @media only screen and (max-width: 575px) {
        font-family: ${Theme["$base-font-family"]};
      }
    }

    .form-label-group input::-webkit-input-placeholder {
      color: transparent;
    }

    .form-label-group input:-ms-input-placeholder {
      color: transparent;
    }

    .form-label-group input::-ms-input-placeholder {
      color: transparent;
    }

    .form-label-group input::-moz-placeholder {
      color: transparent;
    }

    .form-label-group input::placeholder {
      color: transparent;
    }

    .form-label-group input:not(:placeholder-shown) {
      padding-top: calc(
        var(--input-padding-y) + var(--input-padding-y) * (2 / 3)
      );
      padding-bottom: calc(var(--input-padding-y) / 3);
      padding: 22px 20px 10px;
      font-family: ${Theme["$medium-font-family"]};
    }

    .form-label-group input:not(:placeholder-shown) ~ label {
      padding-top: calc(var(--input-padding-y) / 3);
      padding-bottom: calc(var(--input-padding-y) / 3);
      padding: 0 20px;
      color: ${Theme["$gray-38"]};
      font-size: ${Theme["$e-small-f-size"]};
    }

    @media only screen and (max-width: 575px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .modal-body {
        padding: 0 45px 60px;
        max-width: 100%;

        h2 {
          margin: 10px 0 0;
        }

        .forgot-link {
          font-size: calc(${Theme["$small-f-size"]} + 1px);
        }

        .modal-sub-heading {
          font-size: ${Theme["$base-f-size"]};
        }

        &.notification-body {
          margin: 0px auto;
          max-width: 93%;
        }
      }
    }
  }
`;

export const BigBoxImageSlider = styled.div`
  max-width: 586px;
  width: 100%;
  cursor: pointer;
  margin: 5px 0;

  @media only screen and (max-width: 1440px) {
    max-width: 552px;

    .heart-circle {
      top: 11px !important;
      right: 15px !important;
    }
  }

  @media only screen and (max-width: 1040px) {
    max-width: 400px;

    .slide-inner {
      margin-left: -20px !important;

      .heart-circle {
        top: 13px !important;
        right: 13px !important;
      }

      img {
        height: 300px !important;
      }
    }
  }

  @media only screen and (max-width: 575px) {
    .slide-inner {
      img {
        height: 250px !important;
      }
    }
  }

  &.empty-slide {
    max-width: 440px;

    @media only screen and (max-width: 1600px) {
      max-width: 348px;
    }

    @media only screen and (max-width: 1440px) {
      max-width: 330px;
    }

    @media only screen and (max-width: 1200px) {
      max-width: 280px;
    }

    @media only screen and (max-width: 992px) {
      max-width: 232px;
    }

    @media only screen and (max-width: 768px) {
      max-width: 75px;
    }

    @media only screen and (max-width: 575px) {
      max-width: 60px;
    }

    @media only screen and (max-width: 400px) {
      max-width: 50px;
    }
  }

  .slide-inner {
    margin-left: -35px;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
  }

  img {
    height: 330px;
    width: 100%;
    opacity: 0.9;
    border-radius: 5px;

    @media only screen and (max-width: 767px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .heart-circle {
    position: absolute;
    top: 7px;
    right: 25px;
    width: 30px;
    height: 30px;
    background-color: ${Theme["$white-color"]};
    border-radius: 50%;

    i {
      color: ${Theme["$red-color"]};
      padding-top: 10px;
      padding-left: 8px;
      font-size: ${Theme["$base-f-size"]};
    }
  }

  .slide-text {
    margin-top: -120px;
    padding: 10px 20px;
    width: 100%;
    position: relative;

    h4 {
      margin: 7px 0;
      letter-spacing: 1px;
      font-weight: 700;
      max-width: 260px;
    }

    .from-text {
      b {
        font-family: ${Theme["$title-font-family"]};
      }
    }

    .days-ago {
      font-family: ${Theme["$medium-font-family"]};
    }
  }

  @media only screen and (max-width: 768px) {
    max-width: 55%;

    img {
      height: 250px;
    }
  }

  @media only screen and (max-width: 600px) {
    max-width: 75%;

    img {
      height: 250px;
    }
  }

  @media only screen and (max-width: 380px) {
    max-width: 78%;

    img {
      height: 180px;
    }
  }
`;

export const SmallImageSlider = styled.div`
  max-width: 292px;
  width: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  margin: 17px 0;

  @media only screen and (max-width: 1440px) {
    max-width: 275px;

    img {
      height: 156px !important;
    }
  }
  
  @media only screen and (max-width: 400px) {
    &.slider-sm {
      max-width: 90%;
      img {
        height: auto !important;
      }
    }
  }

  &.empty-slide {
    max-width: 445px;

    @media only screen and (max-width: 1600px) {
      max-width: 350px;
    }

    @media only screen and (max-width: 1440px) {
      max-width: 335px;
    }

    @media only screen and (max-width: 1200px) {
      max-width: 280px;
    }

    @media only screen and (max-width: 992px) {
      max-width: 237px;
    }

    @media only screen and (max-width: 768px) {
      max-width: 90px;
    }

    @media only screen and (max-width: 575px) {
      max-width: 75px;
    }

    @media only screen and (max-width: 400px) {
      max-width: 50px;
    }
  }

  .saved-label {
    color: ${Theme["$gray-25"]};
    font-size: 15px;
    position: absolute;
    display: block;
    z-index: 9999;
    right: 3px;
    top: -22px;
  }

  .slide-inner {
    margin-left: -6px;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);
  }

  &.offer-list-box {
    margin: 30px 20px 0 0;
    float: left;

    &:nth-child(4n) {
      // margin-right: 0px;
    }

    @media only screen and (max-width: 1262px) {
      &:nth-child(4n) {
        margin-right: 22px;
      }
    }

    @media only screen and (max-width: 626px) {
      margin: 15px auto 0;
      float: none;

      &:nth-child(4n) {
        margin: 15px auto 0;
      }
    }
  }

  img {
    height: 170px;
    max-width: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .heart-circle {
    position: absolute;
    top: 8px;
    right: 12px;
    width: 30px;
    height: 30px;
    background-color: ${Theme["$white-color"]};
    border-radius: 50%;

    i {
      color: ${Theme["$red-color"]};
      padding-top: 10px;
      padding-left: 8px;
      font-size: ${Theme["$base-f-size"]};
    }
  }

  .slide-info-text {
    background-color: ${Theme["$gray-103"]};
    padding: 11px 18px;
    max-width: 100%;
    width: 100%;
    min-height: 140px;
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: 0px;

    h5 {
      margin: 6px 0;
      line-height: 18px;
      font-size: calc(${Theme["$base-f-size"]} + 1px);
    }

    .from-text {
      color: ${Theme["$gray-25"]};
      margin-top: 0;

      b {
        font-family: ${Theme["$title-font-family"]};
      }
    }

    .days-ago {
      color: ${Theme["$gray-25"]};
      position: absolute;
      bottom: 12px;
      font-size: ${Theme["$small-f-size"]};
      font-family: ${Theme["$medium-font-family"]};
    }

    @media only screen and (max-width: 575px) {
      min-height: 120px;
    }
  }

  @media only screen and (max-width: 380px) {
    max-width: 67%;

    &.offer-list-box {
      max-width: 292px;
    }
  }
`;

export const RoundedImageSlide = styled.div`

 
  &.empty-slide {
    width: 305px;

    @media only screen and (max-width: 1440px) {
      width: 230px;
    }

    @media only screen and (max-width: 1200px) {
      width: 190px;
    }

     @media only screen and (max-width: 992px) {
      width: 155px;
    }

    @media only screen and (max-width: 768px) {
      width: 75px;
      margin; 0;
    }

    @media only screen and (max-width: 575px) {
      width: 60px;
      margin; 0;
    }
  }

  .slide-inner {
    margin-left: 0px;
    margin-right: 10px;
    width: 140px;

    @media only screen and (max-width: 1440px) {
      width: 135px;
      margin-right: 7px;

      img {
        height: 135px !important;
      }
    }

    @media only screen and (max-width: 575px) {
      width: 125px;
      margin-right: 0px;

      img {
        height: 125px !important;
      }
    }
  }

  &.rounded-img-slider {
    img {
      width: 100%;
      border-radius: 50%;
      height: 140px;
      box-shadow: 0 4px 10px 7px rgba(0, 0, 0, 0.3);
    }

    h4 {
      text-align: center;
      margin: 0px auto 0px;
      font-family: ${Theme["$title-font-family"]};
      position: absolute;
      bottom: -20px;
      width: 100%;
      letter-spacing: 0.5px;
    }
  }
`;

export const LargeModalBox = styled.div`
  .modal {
  	z-index: 99999;
    padding-right: 0 !important;
  }

  .modal-dialog {
    .modal-content {
      border: 0;
      border-radius: 17px;
     

      @media only screen and (max-width: 575px) {
        // height: 400px;
        overflow-y: scroll;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
        .modal-header {
          padding: 0 20px;
          background-color: ${Theme["$gray-94"]};
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          border: 0;

          .close {
            padding: 5px 16px !important;
            opacity: 1;
            font-size: ${Theme["$primary-title-f-size"]};
            font-family: ${Theme["$thin-font-family"]};
            color: ${Theme["$gray-15"]};
            margin-top: 0;
            font-weight: 600;
          }
        }

        .modal-body {
          text-align: center;
          color: ${Theme["$white-color"]};
          background-color: ${Theme["$gray-94"]};
          padding: 0 1rem 1rem;

          .image-linked {
            margin-bottom: 10px;
            border: 1px solid ${Theme["$white-80"]};
            cursor: pointer;
            width: 280px;

            @media only screen and (max-width: 575px) {
              width: 180px;
            }
          }

          h2 {
            padding: 30px 0;

            @media only screen and (max-width: 575px) {
              max-width: 300px;
              margin: 0 auto;
              padding: 10px 0;
            }
          }

          p {
            color: ${Theme["$white-color"]};
            margin-bottom: 3px;
            font-family: ${Theme["$thin-font-family"]};
            font-size: calc(${Theme["$medium-f-size"]} - 1px) !important;

            @media only screen and (max-width: 575px) {
              font-size: ${Theme["$base-f-size"]} !important;
            }
          }

          .btn-primary {
            letter-spacing: 1px;
            font-size: 15px;

            @media only screen and (max-width: 400px) {
              width: 90%;
            }
          }
        }

        .modal-footer {
          position: relative;
          border: 0;
          background-color: ${Theme["$gray-65"]};
          padding: 10px 25px;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;

          .container {
            max-width: 100%;
          }

          .footer-left {
            padding-right: 30px;
            @media only screen and (max-width: 991px) {
              padding-right: 0px;
            }
          }

          .footer-right {
            padding-left: 40px;
            @media only screen and (max-width: 991px) {
              padding-left: 0px;
            }
          }

          .btn-primary {
            letter-spacing: 1px;
            font-size: 15px;
          }

          p {
            font-family: ${Theme["$medium-font-family"]};
            color: ${Theme["$gray-6"]};

            &.or {
              background-color: ${Theme["$gray-65"]};
              color: white;
              width: 30px;
              height: 35px;
              position: absolute;
              right: -15px;
              top: 39%;
              margin-bottom: 0;
              padding-top: 5px;
            }
          }

          .border-right {
            border-right: 1.1px solid ${Theme["$white-color"]} !important;
          }

          &.after-login {
            background-color: ${Theme["$gray-94"]};
            padding: 0;

            .heart-circle {
              margin: -20px auto 20px;
            }
          }

          form {
            .form-group {
              max-width: 290px;
              position: relative;
              margin: 0 auto;

              .form-control {
                position: relative;
                border: 0;
                border-radius: 25px;
                padding: 10px 25px;
                height: 50px;

                &::placeholder {
                  font-family: ${Theme["$medium-font-family"]};
                  color: ${Theme["$gray-72"]};
                  font-size: ${Theme["$base-f-size"]};
                }
              }

              .send-btn {
                border: 0;
                background: none;
                position: absolute;
                top: 15px;
                right: 14px;
              }

              i {
                color: ${Theme["$primary-color"]};
              }
            }
          }
        }

        .heart-circle {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          text-align: center;
          margin: -38px auto 0;
          background-color: ${Theme["$white-color"]};

          i {
            color: ${Theme["$red-color"]};
            padding-top: 10px;
            font-size: 24px;
            padding-left: 1.5px;
          }
        }

        .join-free-link {
          font-size: ${Theme["$base-f-size"]};
          font-family: ${Theme["$medium-font-family"]};

          text-decoration: underline;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .modal-dialog {
      margin: 30px auto !important;

      .modal-content {
        .modal-body {
          padding: 1rem;
        }

        .modal-footer {
          padding: 15px 25px 40px;
        }
      }
    }
  }

  @media only screen and (max-width: 575px) {
    .modal-dialog {
      margin: 0 !important;
      position: absolute;
      width: 100%;
      bottom: 0;
      .modal-content {
        .modal-header {
          display:block;
        }
        .modal-footer {
          display:block;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          padding: 15px 25px 10px;
          p {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

export const Button = styled.button`
  &.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 0;
    padding: 11px 24px;
    border-radius: 4px;
    font-size: ${Theme["$medium-f-size"]};
    font-family: ${Theme["$title-font-family"]};

    &.btn-primary {
      background-color: ${Theme["$primary-color"]};
      border-color: ${Theme["$primary-color"]};

      &:focus {
        border-color: ${Theme["$secondary-color"]};
        background-color: ${Theme["$secondary-color"]};
        outline: none;
        box-shadow: none;
      }

      &:hover {
        border-color: ${Theme["$secondary-color"]};
        background-color: ${Theme["$secondary-color"]};
      }

      &:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
      }
    }

    &.btn-secondary {
      background-color: ${Theme["$gray-27"]};
      border-color: ${Theme["$gray-27"]};
      border-radius: 0;

      &:focus {
        border-color: ${Theme["$gray-30"]};
        background-color: ${Theme["$gray-30"]};
        color: ${Theme["$white-color"]};
        outline: none;
        box-shadow: none;
      }

      &:hover {
        border-color: ${Theme["$gray-30"]};
        background-color: ${Theme["$gray-30"]};
        color: ${Theme["$white-color"]};
      }

      &:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
      }
    }
  }
`;

export const ErrorLabel = styled.label`
  color: ${Theme["$dark-red"]} !important;
  font-family: ${Theme["$base-font-family"]} !important;
  font-size: ${Theme["$small-f-size"]};
  text-transform: none !important;
  size: 10px;
  padding: 0;
  margin: 0;
`;

export const AccountContainerOuter = styled.div`
  background-color: ${Theme["$white-90"]};
  color: ${Theme["$gray-85"]} !important;
  box-shadow: 0px 0px 47px 20px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 65px 100px 100px 50px;
  margin-bottom: 50px;

  .profile-info {
    font-size: ${Theme["$base-f-size"]};

    .profile-photo {
      float: left;
      margin-right: 20px;
      position: relative;

      img {
        width: 50px;
        height: 50px;
        border-radius: 6px;
      }

      i {
        color: ${Theme["$white-color"]};
        background: ${Theme["$primary-color"]};
        position: absolute;
        right: -7px;
        top: -7px;
        font-size: 15px;
        padding: 5px;
        font-size: 10px;
        border-radius: 25px;
        cursor: pointer;
      }
    }

    i.welcome-back {
      color: ${Theme["$gray-25"]};
      font-family: ${Theme["$medium-font-family"]};
    }

    .profile-name {
      b {
        letter-spacing: 0;
      }
    }
  }

  .dark-bold-heading {
    color: ${Theme["$gray-81"]};
    font-family: ${Theme["$title-font-family"]};
    font-size: 21px;
    margin: 0;
  }

  ul {
    &.account-sidebar {
      list-style-type: none;
      padding-left: 0;

      li {
        position: relative;
        padding: 8px 15px;
        font-size: ${Theme["$medium-f-size"]};
        font-family: ${Theme["$medium-font-family"]};
        border-radius: 5px;
        width: 85%;
        cursor: pointer;
        margin-bottom: 2px;

        img {
          width: 18px;
          margin-right: 19px;

          &.detail {
            margin-right: 22px;
          }

          &.blue {
            display: none;
          }
        }

        &:hover {
          background-color: ${Theme["$white-80"]};
          color: ${Theme["$primary-color"]};

          img {
            &.blue {
              display: inline-block;
            }

            &.gray {
              display: none;
            }
          }
        }

        &.active {
          background-color: ${Theme["$white-80"]};
          color: ${Theme["$primary-color"]};

          img {
            &.blue {
              display: inline-block;
            }

            &.gray {
              display: none;
            }
          }
        }

        .log-out-loader {
          position: absolute;
          top: 11px;
          left: 38px;
          width: 85%;
          margin: 0 auto;
        }

        &.loader-li {
          width: 85%;
        }
      }
    }
  }

  .profile-photo-heading {
    color: ${Theme["$gray-85"]};
    font-family: ${Theme["$title-font-family"]};
  }

  .white-panel {
    background-color: ${Theme["$white-color"]};
    border-radius: 15px;
    padding: 50px 45px;

    .my-details-headings {
      color: ${Theme["$gray-81"]};
      font-family: ${Theme["$title-font-family"]};
      font-size: ${Theme["$extra-medium-f-size"]};
      border-bottom: 1px solid ${Theme["$gray-6"]};
      padding-bottom: 10px;
      margin-top: 50px;
    }

    .email-notification-subtitle {
      color: ${Theme["$gray-81"]};
      font-family: ${Theme["$title-font-family"]};
      font-size: ${Theme["$extra-medium-f-size"]};
      padding-bottom: 10px;
      margin-top: 50px;
    }

    p {
      font-size: ${Theme["$base-f-size"]} !important;
      font-family: ${Theme["$medium-font-family"]};
      line-height: 18px;
    }

    .form-group {
      margin-bottom: 25px;

      label {
        color: ${Theme["$gray-51"]};
        text-transform: uppercase;
        font-family: ${Theme["$title-font-family"]};
        font-weight: unset !important;
        margin-bottom: 0px;
      }

      .form-control {
        padding: 10px 12px;
        background-color: ${Theme["$white-90"]};
        border: 1.2px solid ${Theme["$gray-3"]};
        font-family: ${Theme["$medium-font-family"]};
        min-height: 47px;
        color: ${Theme["$gray-96"]};

        &::placeholder {
          text-transform: capitalize !important;
          font-size: ${Theme["$base-f-size"]};
          letter-spacing: 0.5px;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .btn {
      font-size: 17px;

      &.btn-primary {
        letter-spacing: 0.5px;
        width: 110px;
      }

      &.btn-secondary {
        letter-spacing: 0.5px;
        width: 110px;
      }
    }

    .notification-save {
      font-size: ${Theme["$extra-medium-f-size"]};
      width: 120px;
    }

    .membership-details {
      font-size: ${Theme["$medium-f-size"]};

      label {
        color: ${Theme["$gray-86"]};
        font-family: ${Theme["$medium-font-family"]};
      }

      .joining-date {
        color: ${Theme["$gray-86"]};
      }
    }

    .btn-primary {
      border-radius: 0;
    }
  }

  @media only screen and (max-width: 1476px) {
    ul {
      &.account-sidebar {
        li {
          width: 100%;

          &.loader-li {
            width: 100%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1290px) {
    padding: 65px 50px 100px 50px;

    ul {
      &.account-sidebar {
        li {
          img {
            margin-right: 12px;
            &.detail {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    ul {
      &.account-sidebar {
        li {
          width: unset;
          display: inline-block;

          &.loader-li {
            max-width: 100px;
            width: 100%;
          }

          &:not(:last-child) {
            margin-right: 2px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 15px;

    ul {
      &.account-sidebar {
        li {
          padding: 8px 10px;

          i {
            margin-right: 7px;
          }

          img {
            margin-right: 7px;
            width: 19px;
          }

          &:not(:last-child) {
            margin-right: 1px;
          }
        }
      }
    }
    .white-panel {
      .btn {
        font-size: 16px;
      }
    }
  }

  @media only screen and (max-width: 490px) {
    ul {
      &.account-sidebar {
        li {
          padding: 8px 15px;
          display: block;
          margin-right: 0 !important;

          i {
            margin-right: 15px;
          }

          img {
            margin-right: 15px;
          }

          &:not(:last-child) {
            margin-right: 1px;
          }

          &.loader-li {
            width: 100%;
            max-width: 100%;
          }

          &:first-child {
            i {
              margin-right: 22px;
            }
          }

          &:nth-child(2) {
            i {
              margin-right: 15px;
            }
          }

          &:last-child {
            i {
              margin-right: 18px;
            }
          }
        }
      }
    }

    .white-panel {
      padding: 25px 25px 100px;
    }
  }
`;

export const UpdatePhotoModal = styled.div`
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: 20px;

        .close {
          font-size: 30px;
          margin: 0;
          padding: 0;
        }
      }
      .modal-body {
        padding: 25px;
      }
    }
  }
`;

export const PaginationStyled = styled.div`
  max-width: 550px;
  margin: 2px auto 0;

  ul.pagination {
    padding: 0;
    display: block;
    justify-content: center;

    li {
      display: inline-block;
    }

    .page-item {
      .page-link {
        font-size: ${Theme["$base-f-size"]} ;
        color: ${Theme["$base-color"]} !important;
        background: ${Theme["$gray-50"]};
        border-color: ${Theme["$gray-50"]};
        padding: 0.7rem 1.85rem
        border-radius: 2px;
      }

      &:not(:last-child) {
        margin-right: 3px;
      }

      &:hover {
        .page-link {
          background: ${Theme["$primary-color"]};
          border-color: ${Theme["$primary-color"]};
        }
      }

      &.active {
        .page-link {
          color: ${Theme["$white"]};  !important;
          background: ${Theme["$primary-color"]};
          border-color: ${Theme["$primary-color"]};
        }
      }

      .page-link {
          &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    
  }
  
`;

export const CustomCheckbox = styled.div`
  margin-top: 40px;

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-top: 1px solid ${Theme["$gray-6"]};
  }

  .custom-control {
    custom-control-input {
      &:focus {
        outline: none;
      }
    }

    .custom-control-label {
      padding-left: 30px;

      .label-title {
        font-family: ${Theme["$title-font-family"]};
        font-size: ${Theme["$medium-f-size"]};
        color: ${Theme["$gray-81"]};
        text-transform: capitalize;
      }

      p {
        color: ${Theme["$gray-54"]};
        text-transform: none !important;
        font-size: ${Theme["$medium-f-size"]} !important;
      }

      &:before {
        width: 2rem;
        height: 2rem;
        border: ${Theme["$gray-6"]} solid 1.3px;
        background: ${Theme["$white-90"]};
        border-radius: 0.3rem;
      }

      &:after {
        width: 2.2rem;
        height: 2rem;
        left: -1.6rem;
      }
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: ${Theme["$primary-color"]};
    background: ${Theme["$white-90"]};
    border-color: ${Theme["$gray-6"]};
  }

  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-image: url(${RightCheck});
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
    border-color: ${Theme["$gray-6"]};
  }
`;
