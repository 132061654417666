import React from "react";
import "react-multi-carousel/lib/styles.css";

import { RoundedImageSlide } from "../../../theme/css/Global";

import Flickity from "react-flickity-component";
import "../../../theme/css/flickity.css";

const _ = require("lodash");

function CircleImageSlider(props) {
  const { history, sliderData, sliderType, brands } = props;

  const redirectToOfferList = (event, slider) => {
    if(brands === true)
    {
      history.push(`/brand/${slider.name}`);
    } else {
      history.push(
        { pathname: "/offers", search: new URLSearchParams({type: sliderType, name: slider.name}).toString() },
        {
          offerType: slider.name + " Offers",
          filters: { [sliderType]: slider.name, page: 1 },
        }
      );
    }
  };

  return (
    <Flickity
      options={{
        pageDots: false,
        contain: true,
        groupCells:  '90%',
        cellAlign: "left",
        freeScroll: true,
        freeScrollFriction: 0.02,
      }}
    >
      {sliderData
        ? sliderData.map((slider, index) => (
            <React.Fragment key={slider.id + "_" + (Math.floor(Math.random() * 9999) + 999)}>
              <RoundedImageSlide
                className={(sliderData.length - 1 !== index) ? "rounded-img-slider" : "rounded-img-slider last-slide"}
                onClick={(event) => redirectToOfferList(event, slider)}
              >
                <div className="slide-inner">
                  <img
                    src={
                      _.get(slider, "image")
                        ? slider?.image
                        : null
                    }
                    alt={slider?.name}
                  />
                  {sliderType !== "brand_name" ? (
                    <h4 className="bold">{slider.name}</h4>
                  ) : (
                    ""
                  )}
                </div>
              </RoundedImageSlide>
              {sliderData.length - 1 === index ? (
                <React.Fragment>
                  <RoundedImageSlide className="empty-slide"/>
                </React.Fragment>
              ) : (
                ""
              )}
            </React.Fragment>
          ))
        : ""}
    </Flickity>
  );
}

export default CircleImageSlider;
