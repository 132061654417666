import React from "react";
import * as actions from "./AuthService";
import { Redirect, Route } from "react-router-dom";

import MyAccountContainer from '../components/application/user/MyAccountContainer';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // Add your own authentication on the below line.
  const isLoggedIn = actions.isLoggedIn();
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <MyAccountContainer {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
