import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {Theme} from "../../../theme/css/theme";
import {ErrorLabel} from "../../../theme/css/Global";
import SendImg from "../../../theme/images/sed.png";
import Logo from "../../../theme/images/logo.svg";
import * as actions from "../../../store/actions";
import CircleLoader from "../../shared/CircleLoader";
import {ToastsStore} from "react-toasts";

const _ = require("lodash");

function FooterComponent(props) {
	useEffect(() => {
		setIsLogin(localStorage.getItem("token"));
	});

	const [isLogin, setIsLogin] = useState(localStorage.getItem("token"));
	const [emailId, setEmailId] = useState({email: ""});
	const dispatch = useDispatch();

	const {emailDetails, isLoading} = useSelector((state) => ({
		emailDetails: state.userState.registerEmailDetails,
		isLoading: state.userState.registerEmailLoading,
	}));

	let error = useSelector((state) => state.userState.registerEmailError);

	const redirectToPage = (redirectLink) => {
		let my_splits = redirectLink.split("#", 2);
		let newRedirectLink = my_splits[0];
		let my_anchor = my_splits[1];

		window.scrollTo(0, 0);
		props.history.push({
			pathname: newRedirectLink,
		});

		if (my_anchor) {
			let element = document.getElementById(my_anchor);
			if (element) element.scrollIntoView()
		}
	};

	const handleFieldChange = (event) => {
		setEmailId({email: event.target.value});
		error[event.target.id] = "";
	};

	const handleSubmit = (event) => {
		event.preventDefault(true);
		dispatch(actions.registerEmailId(emailId.email));
	};

	const clearError = () => {
		setEmailId({email: ""});
		dispatch(actions.clearEmailError());
	};

	useEffect(() => {
		setTimeout(() => {
			if (_.get(error, "email")) {
				clearError();
			}

		},5000)
	}, [error])
	useEffect(() => {
		if (_.get(emailDetails, "id")) {
			ToastsStore.success(
				"Thank you for signing up!",
				4000
			);
			setEmailId({email: ""});
		}
	}, [emailDetails])
	return (
		<React.Fragment>
			<Footer>
				<div className="container">
					<div className="row">
						<div className="col-xl-5 col-lg-4 col-lg-4 text-lg-left text-center">
							<img
								onClick={() => redirectToPage("/")}
								className="logo footer-logo"
								src={Logo}
								alt="OffersClubOnline"
							/>
						</div>
						<div className="col-lg-4 text-lg-left text-center mt-lg-0 mt-5">
							<div className="row">
								<div className="col-xl-6">
									<h5 className="footer-heading ">CONTACT US</h5>
									{isLogin !== null ? (
										<div>
											<p className="contact-link mb-0 pt-2">
												{" "}
												helpdesk@offersclub.com{" "}
											</p>
											<p className="contact-link"></p>
										</div>
									) : (
										<p className="contact-link pt-2">
											{" "}
											helpdesk@offersclub.com{" "}
										</p>
									)}
								</div>
								<div className="col-xl-6 follow-us pt-lg-0 pt-4">
									<h5 className="footer-heading mb-md-3 mb-4">FOLLOW US</h5>

									<a href="http://facebook.com/OffersClubCom" target="_blank">
										<i
											className="fa fa-facebook mr-md-4 mr-5"
											aria-hidden="true"
										></i>
									</a>

									<a href="https://twitter.com/club_offers" target="_blank">
										<i
											className="fa fa-twitter mr-md-4 mr-5"
											aria-hidden="true"
										></i>
									</a>

									<a href="https://www.instagram.com/offersclubcom/" target="_blank">
										<i
											className="fa fa-instagram mr-md-4 mr-5"
											aria-hidden="true"
										></i>
									</a>

								</div>
							</div>
						</div>
						{!isLogin && <div className="col-xl-3 col-lg-4  text-lg-left text-center pt-lg-2 pt-5">
							<p className="join-newsletter">
								Sign-up to our newsletter to stay up to date{" "}
								<br className="d-md-none d-block"/> on all the latest offers:
							</p>
							<form onSubmit={handleSubmit}>
								<div className="form-group mt-4">
									<input
										type="email"
										id="email"
										placeholder="Enter your email address"
										className="form-control"
										style={{padding: '0 40px 0 25px'}}
										value={emailId.email}
										onChange={(event) => handleFieldChange(event)}
									/>
									<button type="submit" className="send-btn">
										{isLoading ? (
											<CircleLoader size={20}/>
										) : (
											<img src={SendImg} alt=""/>
										)}
									</button>
									{_.get(error, "email") ? (
										<div>
											<ErrorLabel>{error.email}</ErrorLabel>
										</div>
									) : null}
								</div>
							</form>
						</div>}
					</div>
				</div>
				<div className="footer-bottom">
					<div className="container">
						<div className="row">
							<div className="col-lg-3 text-lg-left text-center order-sm-first order-last">
								<ul>
									<li className="pl-0 pr-0 copyright">
										&copy; 2020-2021 OffersClub. All right reserved.
									</li>
								</ul>
							</div>
							<div className="col-lg-9 text-lg-right  text-center mt-lg-0 mt-4">
								<ul>
									<li>
										<a href="/medicare/" className="footerlink">
											Medicare
										</a>
									</li>
									<li onClick={() => redirectToPage("/privacy-policy")}>
										Privacy Policy
									</li>
									<li onClick={() => redirectToPage("/terms-and-conditions")}>
										Terms & Conditions
									</li>
									<li onClick={() => redirectToPage("/privacy-policy#california-residents")}>
										CA Privacy Rights
									</li>
									<li
										onClick={() =>
											redirectToPage("/do-not-sell-my-personal-information")
										}
									>
										Do Not Sell My Personal Information
									</li>
									<li
										onClick={() =>
											redirectToPage("/unsubscribe")
										}
									>
										Unsubscribe
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</Footer>
		</React.Fragment>
	);
}

export default withRouter(FooterComponent);

const Footer = styled.div`
  background-color: ${Theme["$gray-102"]};
  padding: 55px 0 0;

  .footerlink {
    color: rgb(151, 152, 170);
  }

  .footer-logo {
    max-width: 140px;
    width: 100%;
    cursor: pointer;
  }

  h5 {
    &.footer-heading {
      margin: 10px 0 5px;
    }
  }

  p {
    &.contact-link {
      font-size: calc(${Theme["$base-f-size"]} + 1px) !important;
    }

    &.join-newsletter {
      max-width: 297px;
      margin: 0 auto;
    }
  }

  .form-group {
    position: relative;
    width: 100%;
    max-width: 290px;
    margin: 0 auto;

    input {
      position: relative;
      border-radius: 25px;
      background-color: ${Theme["$gray-97"]};
      border: none;
      height: 2.8rem;
      color: ${Theme["$white-color"]};
      padding: 0 0 0 25px;

      &.form-control {
        &::placeholder {
          font-size: ${Theme["$base-f-size"]};
          color: ${Theme["$gray-25"]};
        }
      }
    }

    .send-btn {
      position: absolute;
      top: 11px;
      right: 10px;
      background: none;
      border: none;
    }
  }

  .follow-us {
    i {
      font-size: ${Theme["$medium-f-size"]};
      color: ${Theme["$primary-color"]};
      cursor: pointer;

      &:hover {
        color: ${Theme["$secondary-color"]};
      }
    }
  }

  .footer-bottom {
    background-color: ${Theme["$gray-97"]};
    padding: 10px 0;
    margin-top: 30px;

    ul {
      list-style-type: none;
      margin-bottom: 0;
      padding-left: 0;

      li {
        display: inline-block;
        font-size: ${Theme["$base-f-size"]};
        color: ${Theme["$gray-25"]};
        margin: 10px 0;
        cursor: pointer;

        &:not(:last-child) {
          padding-right: 18px;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .follow-us {
      i {
        font-size: ${Theme["$e-large-f-size"]};
        color: ${Theme["$primary-color"]};
      }
    }
  }

  @media only screen and (max-width: 575px) {
    padding: 45px 0 0;

    .footer-bottom {
      margin-top: 45px;
      padding: 10px 0;
      ul {
        li {
          display: block;
          padding: 0 0 15px !important;
          margin: 0;

          &.copyright {
            color: ${Theme["$gray-55"]};
          }
        }
      }
    }
  }
`;
