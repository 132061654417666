import { useEffect, useState } from 'react';

function useSubsFromQueryAndStorage() {
  const [subs, setSubs] = useState({
    sub1: '',
    sub2: '',
    sub3: '',
  });

  const [urlparams, setUrlparams] = useState();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    const getSubValue = (key) => {
      const queryValue = query.get(key);
      const defaultMainSiteSub3 = encodeURIComponent(
        window.location.hostname.replaceAll(".", "__")
      );
      const isMainSite = window.location.href.includes('localhost') || window.location.href.includes('offersclub.com');
      const storedValue = localStorage.getItem(key);
      
      if( key === 'sub3' && isMainSite && !queryValue && !storedValue) {
        return defaultMainSiteSub3;
      } else {
        return queryValue !== null ? queryValue : storedValue !== null ? storedValue : '';
      }
    };

    const newSubs = {
      sub1: getSubValue('sub1'),
      sub2: getSubValue('sub2'),
      sub3: getSubValue('sub3'),
    };

    setSubs(newSubs);
    
    const buildUrlParams = (subObj) =>
      Object.entries(subObj)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');

    setUrlparams('?' + buildUrlParams(newSubs));
  }, [window.location.search]);

  return { subs, urlparams };
}

export default useSubsFromQueryAndStorage;