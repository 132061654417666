import React, { useEffect, useState } from "react";

import ImageSlider from "../../shared/imageslider/ImageSlider";
import apiClient from "../../../store/actions/ApiInterceptor";
import useShuffledData from "../../../hooks/useShuffleData/useShuffledData";
// import CircleLoader from "../../shared/CircleLoader";

function HeaderSlider(props) {
  const settings = {
    showThumbs: false,
    showStatus: false,
    swipeable: true,
  };
  const [sliderData, setSliderData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getSliderImages();
  }, []);

  const getSliderImages = () => {
    setIsLoading(true);
    apiClient
    .get("offer/", {
      params:
      {'is_slider': true}
    })
      .then((res) => {
        setIsLoading(false);
        setSliderData(res.data.results);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const shuffledData = useShuffledData(sliderData);

  return (
    <React.Fragment>
      {!isLoading ? (
        sliderData !== null ? (
          <div className="w-100">
            <ImageSlider
              settings={settings}
              sliderData={shuffledData}
              sliderFor="header"
              {...props}
            />
          </div>
        ) : null
      ) : // <CircleLoader />
      null}
    </React.Fragment>
  );
}

export default HeaderSlider;
