import { useState } from 'react';
import useSubsFromQueryAndStorage from '../useSubsFromQueryAndStorage/useSubsFromQueryAndStorage';

const useClickHandler = () => {
  const { subs , urlparams } = useSubsFromQueryAndStorage();

    const [offerDetailsData, setOfferDetailsData] = useState({});
    const [isClear, setIsClear] = useState(false);

    const handleClick = ({ selectedOffer }, offerType ) => {
        const constructNewUrl = () => {
          const baseUrl = selectedOffer.link;
          const hasTrailingSlash = baseUrl.slice(-1) === '/';
          const hasOffersClub = baseUrl.includes('offersclub.com/');
          
          return baseUrl + (!hasTrailingSlash ? '/' : '') + (!hasOffersClub ? urlparams : '');
      };
      
      const newUrlWithParams = constructNewUrl();
      window.gtag('event', 'select_promotion', { 
        promotions_id: selectedOffer.id,
        promotions_name: selectedOffer.title,
        creative_slot: window.location.pathname,
    });
      
      setOfferDetailsData(selectedOffer);
      setIsClear(true);
      
      window.$('#offerDetailsModal').modal('show');
      window.open(newUrlWithParams);
      };

      return { handleClick, offerDetailsData, isClear, setIsClear };
};

export default useClickHandler;