import React, { useEffect, useState } from "react";
import apiClient from "../../../store/actions/ApiInterceptor";
import ImageSlider from "../../shared/imageslider/ImageSlider";
// import CircleLoader from "../../shared/CircleLoader";
import useShuffledData from "../../../hooks/useShuffleData/useShuffledData";

const _ = require("lodash");

function SmallImageSliderComponent(props) {
  const { history, filters, offerType } = props;
  // const [isLoading, setIsLoading] = useState(false);
  const [offersListData, setOffersListData] = useState([]);

  useEffect(() => {
    const ac = new AbortController();
    getOffers();
    return () => ac.abort(); // Abort both fetches on unmount
  }, []);

  const getOffers = () => {
    // setIsLoading(true);
    apiClient
      .get("offer/", {
        params: filters,
      })
      .then((res) => {
        // setIsLoading(false);
        setOffersListData(res.data);
      })
      .catch((error) => {
        // setIsLoading(false);
      });
  };

  const redirectToSeeAll = () => {
    const search = {
      type: Object.keys(filters)[0],
      name:  filters[Object.keys(filters)[0]]
    };
    history.push(
      { pathname: "/offers", search: new URLSearchParams(search).toString() },
      { offerType: offerType, filters: filters }
    );
  };

  const shuffledOffers = useShuffledData(offersListData.results);

  const bindOffers = () => {
    return (
      <React.Fragment>
        {_.get(offersListData, "results") ? (
          <div>
            <div className="container">
              <div className="row mt-3">
                <div className="col-6 ">
                  <h4 className="mb-0">{offerType}</h4>
                </div>
                <div className="col-6 text-right see-all-cursor ">
                  <span
                    className="see-all"
                    onClick={(event) => redirectToSeeAll(event)}
                  >
                    See All
                  </span>
                </div>
              </div>
            </div>
            <div className="small-image-slider">
              <ImageSlider
                sliderData={shuffledOffers}
                sliderFor="square"
                {...props}
              />
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {bindOffers()}
      {/* {!isLoading ? bindOffers() : <CircleLoader />} */}
    </React.Fragment>
  );
}

export default SmallImageSliderComponent;
