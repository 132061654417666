import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import * as actions from "../../../store/actions";
import {LargeModalBox, Button} from "../../../theme/css/Global";
import CircleLoader from "../../shared/CircleLoader";
import {ErrorLabel} from "../../../theme/css/Global";
import {ToastsStore} from "react-toasts";
import useClickHandler from "../../../hooks/useClickHandler/useClickHandler";



const moment = require("moment");
const _ = require("lodash");


function OffersDetailsModal(props) {
	const { offerDetails } = props;
	const selectedOffer = offerDetails;
	const {
		title,
		description,
		expiration_date,
		brand,
		link,
		is_saved,
		phrase_description,
	} = offerDetails;
	const { handleClick } = useClickHandler();


	useEffect(() => {
		if (props.isClear) {
			clearError();
			props.changeFlag();
		}
	});

	const nameToUrl = (name)  => {
		name = name.replace(/[^a-zA-Z ]/g, "");
		name = name.replace(/  +/g, ' ');
		return name.replaceAll(' ', '-');
	}

	// const [offerIndex, setOfferIndex] = useState(null);
	const dispatch = useDispatch();
	const [emailId, setEmailId] = useState({email: ""});

	const {
		UserData,
		savedOfferDetails,
		isSavedOfferDeleted,
		emailDetails,
		isLoading,
	} = useSelector((state) => ({
		UserData: state.userState.UserDetails,
		savedOfferDetails: state.savedOfferState.savedOfferDetails,
		isSavedOfferDeleted: state.savedOfferState.isSavedOfferDeleted,
		emailDetails: state.userState.registerEmailDetails,
		isLoading: state.userState.registerEmailLoading,
	}));

	let error = useSelector((state) => state.userState.registerEmailError);

	const handleFieldChange = (event) => {
		setEmailId({email: event.target.value});
		error[event.target.id] = "";
	};

	const handleSubmit = (event) => {
		event.preventDefault(true);
		dispatch(actions.registerEmailId(emailId.email));
	};

	const clearError = () => {
		setEmailId({email: ""});
		dispatch(actions.clearEmailError());
	};

	useEffect(() => {
		setTimeout(() => {
			if (_.get(error, "email")) {
				clearError();
			}
		}, 5000)
	}, [error])
	//after saved offer

	if (_.get(savedOfferDetails, "id")) {
		if (
			_.get(props.offerDetails, "id") ===
			_.get(savedOfferDetails, "offer_detail.id")
		) {
			props.offerDetails.is_saved.flag = true;
			props.offerDetails.is_saved.saved_offer_id = savedOfferDetails.id;
			dispatch(actions.initialization());
		}
	}

	//after delete offer
	if (_.get(isSavedOfferDeleted, "flag")) {
		if (
			_.get(props.offerDetails, "id") === _.get(isSavedOfferDeleted, "offerId")
		) {
			props.offerDetails.is_saved.saved_offer_id = null;
			props.offerDetails.is_saved.flag = false;
			dispatch(actions.initialization());
		}
	}

	const toggleFavourite = (offerData) => {
		if (localStorage.getItem("token")) {
			if (offerData.is_saved.flag) {
				//if offer from saved slider or saveofferList
				if (_.get(offerData, "is_from_saved_offer")) {
					dispatch(
						actions.unSaveOffer(
							offerData.is_saved.saved_offer_id,
							offerData.is_saved.saved_offer_id
						)
					);
				} else {
					dispatch(
						actions.unSaveOffer(offerData.is_saved.saved_offer_id, offerData.id)
					);
				}
			} else if (_.get(UserData, "id")) {
				dispatch(actions.saveOffer(offerData.id, UserData.id));
			} else {
				ToastsStore.error(
					"Something went wrong. Please refresh the page and try again."
				);
			}
		} else {
			window.$("#offerDetailsModal").modal("hide");
			window.$("#recommendedForSignUp").modal("show");
		}
	};

	const goToTop = () => {
		window.scrollTo(0, 0);
	};

	return _.get(props.offerDetails, "id") ? (
		<React.Fragment>
			<LargeModalBox>
				<div
					className="modal fade"
					tabIndex="-1"
					id="offerDetailsModal"
					role="dialog"
					aria-labelledby="myLargeModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={() => clearError()}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<a onClick={()=>{handleClick(  {selectedOffer}, "Offer Details Modal Image" )}} target="_blank" rel="noopener noreferrer">
								<div>
									<img
										src={
											_.get(props?.offerDetails, "image")
												? (is_saved?.flag)
												? props?.offerDetails?.image
												: props?.offerDetails?.image
												: null
										}
										alt={props?.offerDetails?.title}
										// className="image-linked"		
									/>
								</div>
								</a>
								<p>
									<b className="medium">{_.get(brand, "name")}</b>
								</p>
								<h2>{title}</h2>
								<p className="pb-sm-3 pb-1">
									<b className="medium">Details</b>
								</p>
								<p className="offer-descripiton">{phrase_description}</p>
								<p>{description}</p>
								<a onClick={() => handleClick( {selectedOffer}, "Continue to Store" )} target="_blank" rel="noopener noreferrer">
									<Button className="btn btn-primary mt-sm-5 mt-2 mb-3">
										Continue to Store
									</Button>
								</a>
								<br/>
								<a href={`/offer/${nameToUrl(props.offerDetails.brand.name + " " + props.offerDetails.title)}/${props?.offerDetails?.id}`}>
									Open in new tab
								</a>
							</div>
							<div
								className={
									localStorage.getItem("token")
										? "modal-footer after-login"
										: "modal-footer"
								}
							>
								<div className="w-100 mx-auto-text-center d-md-none d-block">
									<div
										className="heart-circle "
										onClick={() => toggleFavourite(props.offerDetails)}
									>
										<i
											className={
												props.offerDetails?.is_saved?.flag
													? "fa fa-heart"
													: "fa fa-heart-o"
											}
											aria-hidden="true"
										></i>
									</div>
								</div>
								<div className="container">
									{!localStorage.getItem("token") ? (
										<div className="row">
											<div
												className="col-6 text-center border-right pt-4 mt-1 pb-3 d-md-block d-none">
												<div className="footer-left">
													<p>Receive Alerts from OffersClub.com</p>
													<form onSubmit={handleSubmit}>
														<div className="form-group mt-3">
															<input
																type="email"
																id="email"
																placeholder="Enter your email address"
																className="form-control"
																value={emailId.email}
																onChange={(event) => handleFieldChange(event)}
															/>
															<button type="submit" className="send-btn">
																{isLoading ? (
																	<CircleLoader size={20}/>
																) : (
																	<i
																		className="fa fa-paper-plane"
																		aria-hidden="true"
																	></i>
																)}
															</button>
															{_.get(error, "email") ? (
																<div>
																	<ErrorLabel>{error.email}</ErrorLabel>
																</div>
															) : null}
														</div>
													</form>
												</div>
												<p className="or">
													<b>or</b>{" "}
												</p>
											</div>
											<div className="col-md-6 text-center pt-md-4 pb-md-3">
												<div className="footer-right">
													<p>
														Sign up now to save your favorite
														<br className="d-md-none d-block"/> offers
														<br className="d-md-block d-none"/>
														and get great deals!
													</p>
													<Button
														className="btn btn-primary mt-3 mb-3 d-sm-block d-none mx-auto"
														data-toggle="modal"
														data-target="#joinForFree"
														data-dismiss="modal"
														onClick={() => goToTop()}
													>
														Join for Free
													</Button>
													<div
														className="join-free-link d-sm-none d-block mt-2"
														data-toggle="modal"
														data-target="#joinForFree"
														data-dismiss="modal"
														onClick={() => goToTop()}
													>
														Join for Free
													</div>
												</div>
											</div>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</LargeModalBox>
		</React.Fragment>
	) : (
		<React.Fragment>
			<LargeModalBox>
				<div
					className="modal fade"
					tabIndex="-1"
					id="offerDetailsModal"
					role="dialog"
					aria-labelledby="myLargeModalLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</LargeModalBox>
		</React.Fragment>
	);
}

export default OffersDetailsModal;
