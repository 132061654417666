import React, {useEffect, useState} from "react";
import {ToastsStore} from "react-toasts";
import {PaginationComponent} from "../../shared/PaginationComponent";
import apiClient from "../../../store/actions/ApiInterceptor";
import CircleLoader from "../../shared/CircleLoader";
import OffersDetailsModal from "./OffersDetailsModal";
import ImageSlider from "../../shared/imageslider/ImageSlider";
import {BodyContent, SmallImageSlider} from "../../../theme/css/Global";
import {useDispatch, useSelector} from "react-redux";
import useShuffledData from "../../../hooks/useShuffleData/useShuffledData";
import * as actions from "../../../store/actions";

const _ = require("lodash");
const moment = require("moment");

function AllOffers(props) {
	const [isListLoading, setIsListLoading] = useState(false);
	const [offerListData, setOffersListData] = useState([]);
	const dispatch = useDispatch();
	useEffect(() => {
		const ac = new AbortController();
		getOfferList();
		return () => ac.abort(); // Abort both fetches on unmount
	}, []);

	////////// favourite funtionality start //////////

	const {
		UserData,
		savedOfferDetails,
		isSavedOfferDeleted,
		showToaster,
	} = useSelector((state) => ({
		UserData: state.userState.UserDetails,
		savedOfferDetails: state.savedOfferState.savedOfferDetails,
		isSavedOfferDeleted: state.savedOfferState.isSavedOfferDeleted,
		showToaster: state.savedOfferState.showToaster,
	}));

	if (_.get(offerListData, "results", []).length > 0) {
		//after save offer
		if (_.get(savedOfferDetails, "id")) {
			let sliderData = offerListData.results;

			dispatch(actions.initialization());
			setOffersListData({
				...offerListData,
				results: sliderData,
			});

		}

		//after delete offer
		if (_.get(isSavedOfferDeleted, "flag")) {
			let sliderData = offerListData.results;

			dispatch(actions.initialization());
			setOffersListData({
				...offerListData,
				results: sliderData,
			});

		}
	}

	const ChangeToaster = () => {
		dispatch(actions.closeToaster(null));
	};

	if (showToaster !== null) {
		setTimeout(ChangeToaster, 3000);
	}


	const getOfferList = () => {
		setIsListLoading(true);
		apiClient
			.get("offer/", {
				params: {page_size: 1000000},
			})
			.then((res) => {
				setIsListLoading(false);
				setOffersListData(res.data);
			})
			.catch((error) => {
				setIsListLoading(false);
				ToastsStore.error("Unable to fetch offers.");
			});
	};

	const handleClick = (offer) => {
		redirectToPage(`/offer/${offer.id}`);
	}
	
	const shuffledData = useShuffledData(offerListData.results);

	const renderOffers = () => {
		if (_.get(offerListData, "results", []).length > 0) {
			return shuffledData.map((offer, index) => (
				<a href={`/offer/${offer.id}`} className="a-slider-sm">
					<SmallImageSlider key={offer.id + "_" + (Math.floor(Math.random() * 9999) + 999)}
									  className="offer-list-box">

						<img
							src={
								_.get(offer, "image")
									? offer?.image
									: null
							}
							alt={offer?.title}
						/>

						<div className="slide-info-text">
							<div className="from-text">
								<b>{offer.title}</b>
							</div>
							<h5 className="heading" style={{color: '#ffffff'}}>{offer.description}</h5>
							<div className="days-ago">
								Added {moment(new Date()).diff(moment(offer?.created_at), "days")}{" "}
								days ago
							</div>
						</div>
					</SmallImageSlider>
				</a>
			));
		} else {
			return (
				<span>
          <h5>No Offers Yet! </h5>
        </span>
			);
		}
	};

	const redirectToPage = (redirectLink) => {
		props.history.push({
			pathname: redirectLink,
		});
	};


	return (
		<React.Fragment>
			<BodyContent>
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="lighter d-sm-none d-block">
								<span className="pr-2" onClick={() => redirectToPage("/")}>
								  Home
								</span>
							</div>
							<h1 className="list-heading">
								All Offers
								<br className="d-sm-none d-block"/>
								<span className="ml-sm-4">
                  {offerListData?.count} Offers Available
                </span>
							</h1>
							<div className="lighter  d-sm-block d-none">
                <span className="pr-2" onClick={() => redirectToPage("/")}>
                  Home
                </span>

							</div>
						</div>
						<div className="col-12 d-sm-none d-block pt-2">
							<div className="description-para">
								AAG believes in better.
								<br/> At American Advisors Group, we strive to deliver the most
								considerate solution and personalized service to our
								customers-all in an effort to help them and all older adults
								have the retirement they deserve.
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 flex-wrap d-flex justify-content-center-sm">
							{!isListLoading ? renderOffers() : <CircleLoader/>}
						</div>
					</div>

				</div>

			</BodyContent>
		</React.Fragment>
	);
}

export default AllOffers;
