import React, {useEffect, useState} from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";
import {ToastsStore} from "react-toasts";

import {Theme} from "../../../theme/css/theme";
import {Button} from "../../../theme/css/Global";

const _ = require("lodash");

function HeaderImageSlider(props) {
	let {sliderData} = props;
	const {width} = useWindowDimensions();
	const dispatch = useDispatch();
	
	const getConfigurableProps = () => props.settings;
	const {UserData, savedOfferDetails, isSavedOfferDeleted} = useSelector(
		(state) => ({
			UserData: state.userState.UserDetails,
			savedOfferDetails: state.savedOfferState.savedOfferDetails,
			isSavedOfferDeleted: state.savedOfferState.isSavedOfferDeleted,
		})
	);

	const handleOnClick = (slider) => {
		window.gtag('event', 'click_top_slider', {
			  banner_id: slider.id,
			  banner_name: slider.title,
			  creative_slot: window.location.pathname
		  });

		props.onClick({
			selectedOffer: slider,
		});
	};

	// ************ start favorite functionality **************
	if (_.get(savedOfferDetails, "id")) {
		sliderData.map((slider, index) => {
			if (_.get(slider, "id") === _.get(savedOfferDetails, "offer_detail.id")) {
				sliderData[index].is_saved.flag = true;
				sliderData[index].is_saved.saved_offer_id = savedOfferDetails.id;
			}
		});
	}

	if (_.get(isSavedOfferDeleted, "flag")) {
		sliderData.map((slider, index) => {
			if (_.get(slider, "id") === _.get(isSavedOfferDeleted, "offerId")) {
				sliderData[index].is_saved.saved_offer_id = null;
				sliderData[index].is_saved.flag = false;
			}

			if (
				_.get(slider.is_saved, "saved_offer_id") ===
				_.get(isSavedOfferDeleted, "offerId")
			) {
				sliderData[index].is_saved.saved_offer_id = null;
				sliderData[index].is_saved.flag = false;
			}
		});
	}

	const toggleFavourite = (offerData) => {
		if (localStorage.getItem("token")) {
			if (offerData.is_saved.flag) {
				dispatch(
					actions.unSaveOffer(offerData.is_saved.saved_offer_id, offerData.id)
				);
			} else if (_.get(UserData, "id")) {
				dispatch(actions.saveOffer(offerData.id, UserData.id));
			} else {
				ToastsStore.error(
					"Something went wrong. Please refresh the page and try again."
				);
			}
		} else {
			window.$("#recommendedForSignUp").modal("show");
		}
	};
	// ************ end favorite functionality **************

	return (
		<Carousel
			infiniteLoop
			centerMode
			centerSlidePercentage={84.5}
			{...getConfigurableProps()}
		>
			{sliderData
				? sliderData.map((slider) => (
					<React.Fragment key={slider.id + "_" + (Math.floor(Math.random() * 9999) + 999)}>
						<div key={slider.id}>
							{width <= 575 ? (
								<div onClick={() => handleOnClick(slider)}>
									<img
										alt={slider?.title}
										src={
											_.get(slider, "slider_mobile_image")
												? slider.slider_mobile_image
												: null
										}
									/>
								</div>
							) : (
								<div onClick={() => handleOnClick(slider)}>
									<img
										alt={slider?.title}
										src={
											_.get(slider, "slider_desktop_image")
												? slider.slider_desktop_image
												: null
										}
									/>
								</div>
							)}

							{/* dekstop view */}
							<TextCarousel
								className="d-sm-block d-none"
								onClick={() => handleOnClick(slider)}
							>
								<div
									className="from-text"
									style={{color: (slider.textbgcolor || '#FFF')}}
									onClick={() => handleOnClick(slider)}
								>
									From <b>{slider.brand.name}</b>
								</div>
								<h1 style={{color: (slider.textbgcolor || '#FFF')}}>{slider.title}</h1>
								{/* <h4 className="sub-heading">{slider?.description}</h4> */}
								<Button className="btn btn-primary">See Offer</Button>
							</TextCarousel>

							{/* mobile view */}
							<TextCarousel className="d-sm-none d-block">
								<div
									className="from-text"
									onClick={() => handleOnClick(slider)}
								>
									From <b>{slider.brand.name}</b>
								</div>
								<h3>{slider.title}</h3>
								<div
									className="heart-circle"
									onClick={() => toggleFavourite(slider)}
								>
									<i
										className={
											slider.is_saved.flag ? "fa fa-heart" : "fa fa-heart-o"
										}
										aria-hidden="true"
									/>
								</div>
							</TextCarousel>
						</div>
					</React.Fragment>
				))
				: ""}
		</Carousel>
	);
}

export default HeaderImageSlider;

function getWindowDimensions() {
	const {innerWidth: width, innerHeight: height} = window;
	return {
		width,
		height,
	};
}

function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}

const TextCarousel = styled.div`
  text-align: left;
  position: absolute;
  top: 94px;
  left: 7%;

  h1 {
    font-size: calc(${Theme["$primary-title-f-size"]} - 2px);
    max-width: 500px;
    padding: 5px 0 17px;
    width: 100%;
  }

  h4 {
    &.sub-heading {
      font-size: calc(${Theme["$large-f-size"]} - 1px);
      font-family: ${Theme["$base-font-family"]} !important;
    }
  }

  .btn-primary {
    font-size: 17px;
    width: 142px;
    letter-spacing: 0.5px;
    padding: 11px 0;
  }

  .from-text {
    font-size: 20px;
  }

  @media only screen and (max-width: 1440px) {
    top: 70px;

    h1 {
      font-size: ${Theme["$list-title-f-size"]};
    }

    h4 {
      &.sub-heading {
        // font-size: ${Theme["$extra-medium-f-size"]};
      }
    }

    .pink-block {
      font-size: ${Theme["$small-f-size"]};
      max-width: 135px;
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: 1200px) {
    top: 60px;

    h1 {
      font-size: ${Theme["$secondary-title-f-size"]};
      max-width: 400px;
    }

    h4 {
      &.sub-heading {
        font-size: ${Theme["$extra-medium-f-size"]};
      }
    }

    .pink-block {
      font-size: ${Theme["$small-f-size"]};
      max-width: 135px;
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: 768px) {
    top: 50px;
  }

  @media only screen and (max-width: 575.94px) {
    top: unset;
    bottom: 25px;
    left: 25px;
    width: 100%;

    h3 {
      max-width: 220px;
      margin: 3px 0;
      font-size: 20px;
    }

    .from-text {
      font-size: unset;
    }

    .heart-circle {
      position: absolute;
      right: 35px;
      bottom: 10px;
      width: 30px;
      height: 30px;
      background-color: ${Theme["$white-color"]};
      border-radius: 50%;
      margin: 30px auto 0;

      i {
        color: ${Theme["$red-color"]};
        font-size: ${Theme["$base-f-size"]};
        padding-top: 10px;
        padding-left: 8px;
      }
    }
  }

  @media only screen and (max-width: 380px) {
    left: 15px;

    h3 {
      // max-width: 200px;
    }

    .heart-circle {
      right: 25px;
      bottom: 13px;
    }
  }
`;
